import { ReactNode, createContext, useContext, useState } from 'react'

import { LoginResponse, api } from '@shared/api'
import {
  ApplicationBanner,
  PortalVersion,
  FtpSetAppBarTitleEvent,
  FtpSetNavigatorMenuEvent,
  NavigatorMenu,
  FtpSetApplicationBannerEvent,
  FtpSetPortalVersionEvent,
  FtpSetNavigatorShowLocationEvent,
  FtpSetAppShowSearchEvent,
} from '@shared/types'

import { LOCAL_STORAGE_SESSION_EXP_KEY } from './useEnforceLogin'
import { useReportFilters } from './useReportsFilter'

export interface FtpPortalHubCommunicationContextValue {
  setAppBarTitle: (
    newTitle: string,
    newInfoText?: string | undefined,
    newBreadcrumbs?: Array<string> | string | undefined,
    newBackUrl?: string | 'back'
  ) => void
  setApplicationBanner: (banner: ApplicationBanner) => void
  setPortalVersion: (version: PortalVersion) => void
  setNavigatorMenu: (navMenu: NavigatorMenu) => void
  setApplicationLoginData: (data: LoginResponse) => void
  logout: () => void
  clearSearchButton: () => void
  setAppShowSearchBar: (newShowSearchBar: boolean) => void
  setAppShowLocationBar: (newShowLocationBar: boolean) => void
  setNavigatorLocationChanged: () => void
}

export const FtpPortalHubCommunicationContext = createContext<
  FtpPortalHubCommunicationContextValue | undefined
>(undefined)

export const useFtpPortalHubCommunication = () => {
  const context = useContext(FtpPortalHubCommunicationContext)
  if (!context) {
    throw new Error(
      'useFtpPortalHubCommunication must be used within a FtpPortalHubCommunicationProvider'
    )
  }
  return context
}

interface FtpPortalHubCommunicationProviderProps {
  children: ReactNode
}

export const FtpPortalHubCommunicationProvider = ({
  children,
}: FtpPortalHubCommunicationProviderProps) => {
  const [title, setTitle] = useState<string>('')
  const [infoText, setInfoText] = useState<string | undefined>('')
  const [breadcrumbs, setBreadcrumbs] = useState<
    Array<string> | string | undefined
  >()
  const [backUrl, setBackUrl] = useState<string | 'back'>('')
  const [banner, setBanner] = useState<ApplicationBanner | undefined>()
  const [version, setVersion] = useState<PortalVersion | undefined>()
  const [navMenu, setNavMenu] = useState<NavigatorMenu | undefined>()
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false)
  const [showLocationBar, setShowLocationBar] = useState<boolean>(false)
  const { resetAllFilters } = useReportFilters()

  const setAppBarTitle = (
    newTitle: string,
    newInfoText?: string,
    newBreadcrumbs?: Array<string> | string,
    newBackUrl?: string | 'back'
  ) => {
    setTitle(newTitle)
    setInfoText(newInfoText ?? undefined)
    setBreadcrumbs(newBreadcrumbs ?? undefined)
    setBackUrl(newBackUrl ?? undefined)
    dispatchFtpAppTitleEvent(newTitle, newInfoText, newBreadcrumbs, newBackUrl)
  }

  const setApplicationBanner = (newBanner: ApplicationBanner) => {
    setBanner(newBanner)
    dispatchFtpApplicationBannerEvent(newBanner)
  }

  const setPortalVersion = (version: PortalVersion) => {
    setVersion(version)
    dispatchFtpPortalVersionEvent(version)
  }

  const setNavigatorMenu = (menu: NavigatorMenu) => {
    setNavMenu(menu)
    dispatchFtpNavigatorEvent(menu)
  }

  // This will just save data on local storage,
  // the login data isn't needed to be used
  // for other things throughout the hook,
  // so there's no need to have a state for it.
  const setApplicationLoginData = (data: LoginResponse) => {
    localStorage.setItem(
      LOCAL_STORAGE_SESSION_EXP_KEY,
      data.token.expire ? String(data.token.expire) : null
    )
  }

  const logout = async () => {
    await resetAllFilters()
    await api.logout()
    dispatchFtpLogoutEvent()
  }

  const clearSearchButton = () => {
    dispatchFtpClearSearchButtonEvent()
  }

  const setAppShowSearchBar = (newShowSearchBar: boolean) => {
    setShowSearchBar(newShowSearchBar)
    dispatchFtpAppSetSearchEvent(newShowSearchBar)
  }

  const setAppShowLocationBar = (newShowLocationBar: boolean) => {
    setShowLocationBar(newShowLocationBar)
    dispatchFtpAppSetLocationEvent(newShowLocationBar)
  }

  const setNavigatorLocationChanged = () => {
    dispatchFtpNavigatorLocationChangedEvent()
  }

  // The following functions are designed to address the fact that any or all of our
  // microfrontends might get reloaded at any time, and lose the values that we
  // set for them. Rather than try to keep a global event store across Fortis Tech
  // Portal microfrontends (single-spa recommends to avoid this), we will instead
  // keep that limited state (App Bar Title, Navigation Menu, and Application
  // Banner), which is specific to the portal application that is loaded in the
  // application window anyway, in this provider, and re-inform the FtpAppBar and
  // FtpNavigator if/when they are reloaded.
  //
  // This also covers us in the event that one of the FTP microfrontends that we
  // dispatched an event to came up *after* we did. We resend the event in question
  // when the microfrontend *does* load.

  // Note that this context provider takes care of watching for the events and re-
  // upping the AppBar and the Navigator, so the Portal Application only has to set
  // the once.

  const resetAppBarValues = () => {
    dispatchFtpAppTitleEvent(title, infoText, breadcrumbs, backUrl)
    if (showSearchBar) {
      dispatchFtpAppSetSearchEvent(showSearchBar)
    }
  }

  const resetNavigatorValues = () => {
    if (banner) {
      dispatchFtpApplicationBannerEvent(banner)
    }
    if (navMenu) {
      dispatchFtpNavigatorEvent(navMenu)
    }
    if (version) {
      dispatchFtpPortalVersionEvent(version)
    }
    if (showLocationBar) {
      dispatchFtpAppSetLocationEvent(showLocationBar)
    }
  }

  window.addEventListener('FTP_APPBAR_LOADED', resetAppBarValues)
  window.addEventListener('FTP_NAVIGATOR_LOADED', resetNavigatorValues)

  const contextValue: FtpPortalHubCommunicationContextValue = {
    setAppBarTitle,
    setApplicationBanner,
    setPortalVersion,
    setNavigatorMenu,
    setApplicationLoginData,
    logout,
    clearSearchButton,
    setAppShowSearchBar,
    setAppShowLocationBar,
    setNavigatorLocationChanged,
  }

  return (
    <FtpPortalHubCommunicationContext.Provider value={contextValue}>
      {children}
    </FtpPortalHubCommunicationContext.Provider>
  )
}

const dispatchFtpAppTitleEvent = (
  newTitle: string,
  infoText?: string,
  breadcrumbs?: Array<string> | string,
  backUrl?: string | 'back'
) => {
  const appBarTitleEvent = new Event(
    'FTP_APPBAR_SET_TITLE'
  ) as FtpSetAppBarTitleEvent
  appBarTitleEvent.title = newTitle
  appBarTitleEvent.infoText = infoText ?? undefined
  appBarTitleEvent.breadcrumbs = breadcrumbs ?? undefined
  appBarTitleEvent.backUrl = backUrl ?? undefined
  window.dispatchEvent(appBarTitleEvent)
}

const dispatchFtpApplicationBannerEvent = (banner: ApplicationBanner) => {
  const applicationBannerEvent = new Event(
    'FTP_NAVIGATOR_SET_APPLICATION_BANNER'
  ) as FtpSetApplicationBannerEvent
  applicationBannerEvent.banner = banner

  window.dispatchEvent(applicationBannerEvent)
}

const dispatchFtpPortalVersionEvent = (version: PortalVersion) => {
  const portalVersionEvent = new Event(
    'FTP_NAVIGATOR_SET_PORTAL_VERSION'
  ) as FtpSetPortalVersionEvent
  portalVersionEvent.version = version

  window.dispatchEvent(portalVersionEvent)
}

const dispatchFtpNavigatorEvent = (menu: NavigatorMenu) => {
  const navigatorEvent = new Event(
    'FTP_SET_NAVIGATOR_MENU'
  ) as FtpSetNavigatorMenuEvent
  navigatorEvent.navigatorMenu = menu
  window.dispatchEvent(navigatorEvent)
}

const dispatchFtpLogoutEvent = () => {
  const logoutEvent = new Event('FTP_LOGOUT')
  window.dispatchEvent(logoutEvent)
}

const dispatchFtpClearSearchButtonEvent = () => {
  const clearSearchButtonEvent = new Event('FTP_CLEAR_SEARCH_BUTTON')
  window.dispatchEvent(clearSearchButtonEvent)
}

const dispatchFtpAppSetSearchEvent = (showSearchBar: boolean) => {
  const appBarSearchEvent = new Event(
    'FTP_APPBAR_SET_SEARCH'
  ) as FtpSetAppShowSearchEvent
  appBarSearchEvent.showSearchBar = showSearchBar
  window.dispatchEvent(appBarSearchEvent)
}

const dispatchFtpAppSetLocationEvent = (showLocationBar: boolean) => {
  const appBarLocationEvent = new Event(
    'FTP_NAVIGATOR_SET_SHOW_LOCATION'
  ) as FtpSetNavigatorShowLocationEvent
  appBarLocationEvent.showLocationBar = showLocationBar
  window.dispatchEvent(appBarLocationEvent)
}

const dispatchFtpNavigatorLocationChangedEvent = () => {
  const navigatorLocationChanged = new Event('FTP_NAVIGATOR_LOCATION_CHANGED')
  window.dispatchEvent(navigatorLocationChanged)
}
