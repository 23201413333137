import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { Location } from '@shared/api'
import { useLocations } from '@shared/hooks'
import { MerchantIcon } from '@shared/icons'

interface LocationOptionProps {
  location: Location
  matchText?: string
  guidingId?: string
  onSelect: (location: Location) => void
}

const useStyles = tss
  .withParams<{ isSelected: boolean }>()
  .withName('LocationOption')
  .create(({ theme, isSelected }) => ({
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '18px',
      marginBottom: '8px',
    },
    label: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '12px',
      marginRight: '4px',
    },
    value: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '12px',
    },
    container: {
      backgroundColor: isSelected ? '#FBECE9' : 'transparent',
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: theme.palette['neutral-100'],
      },
    },
    icon: {
      width: '24px',
      height: '24px',
    },
  }))

export const LocationOption: FC<LocationOptionProps> = ({
  location,
  matchText,
  guidingId,
  onSelect,
}) => {
  const { t } = useTranslation()
  const { selectedLocation } = useLocations()
  const { classes } = useStyles({
    isSelected: selectedLocation?.id === location.id,
  })

  const highlightMatchingText = (text, forceDisplay = false) => {
    if (!matchText || !text) return text

    const matchRegex = RegExp(matchText, 'ig')

    // Matches array needed to maintain the correct letter casing
    const matches = [...text.matchAll(matchRegex)]

    if (!matches.length && !forceDisplay) return undefined

    return text.split(matchRegex).map((nonBoldText, index, arr) => (
      <Fragment key={index}>
        {nonBoldText}
        {index + 1 !== arr.length && <mark>{matches[index]}</mark>}
      </Fragment>
    ))
  }

  const defaultACH = location.product_transactions.find(
    (merchantAccount) => merchantAccount.id === location.default_ach
  )
  const defaultCC = location.product_transactions.find(
    (merchantAccount) => merchantAccount.id === location.default_cc
  )

  return (
    <Box
      width="100%"
      className={classes.container}
      onClick={() => onSelect(location)}
      data-testid={`location-option-${location.id}`}
      data-guiding-id={guidingId}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        columns={15}
      >
        <Grid item xs={1}>
          <MerchantIcon className={classes.icon} />
        </Grid>
        <Grid item xs={14}>
          <Typography variant="body1" className={classes.title}>
            {highlightMatchingText(location.name, true)}
          </Typography>
          <Grid container rowGap="5px" columnSpacing="5px">
            {defaultACH && (
              <Fragment key={defaultACH.id}>
                {defaultACH.account_number && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-ach')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultACH.account_number, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultACH.title && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.title-ach')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultACH.title, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultACH.fortis_id && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-ach-fortis-id')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultACH.fortis_id, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultACH.merchant_id && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-ach-merchant-id')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultACH.merchant_id, true)}
                    </Typography>
                  </Grid>
                )}
              </Fragment>
            )}

            {defaultCC && (
              <Fragment key={defaultCC.id}>
                {defaultCC.account_number && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-cc')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultCC.account_number, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultCC.title && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.title-cc')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultCC.title, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultCC.fortis_id && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-cc-fortis-id')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultCC.fortis_id, true)}
                    </Typography>
                  </Grid>
                )}
                {defaultCC.merchant_id && (
                  <Grid item xs={6} display="flex">
                    <Typography className={classes.label}>
                      {t('common.main-cc-merchant-id')}
                    </Typography>
                    <Typography className={classes.value}>
                      {highlightMatchingText(defaultCC.merchant_id, true)}
                    </Typography>
                  </Grid>
                )}
              </Fragment>
            )}

            {location.product_transactions
              .filter(
                (merchantAccount) =>
                  merchantAccount.id !== defaultACH?.id &&
                  merchantAccount.id !== defaultCC?.id
              )
              .map((merchantAccount) => {
                const title = highlightMatchingText(merchantAccount.title)
                const accountNumber = highlightMatchingText(
                  merchantAccount.account_number
                )
                const fortisId = highlightMatchingText(
                  merchantAccount.fortis_id
                )
                const merchantId = highlightMatchingText(
                  merchantAccount.merchant_id
                )
                if (!title && !accountNumber && !fortisId && !merchantId)
                  return null

                return (
                  <Fragment key={merchantAccount.id}>
                    {accountNumber && (
                      <Grid item xs={6} display="flex" key={merchantAccount.id}>
                        <Typography className={classes.label}>
                          {merchantAccount.payment_method === 'cc'
                            ? t('common.additional-cc')
                            : t('common.additional-ach')}
                        </Typography>
                        <Typography className={classes.value}>
                          {accountNumber}
                        </Typography>
                      </Grid>
                    )}
                    {title && (
                      <Grid item xs={6} display="flex" key={merchantAccount.id}>
                        <Typography className={classes.label}>
                          {merchantAccount.payment_method === 'cc'
                            ? t('common.title-additional-cc')
                            : t('common.title-additional-ach')}
                        </Typography>
                        <Typography className={classes.value}>
                          {title}
                        </Typography>
                      </Grid>
                    )}
                    {fortisId && (
                      <Grid item xs={6} display="flex" key={merchantAccount.id}>
                        <Typography className={classes.label}>
                          {t('common.additional-fortis-id')}
                        </Typography>
                        <Typography className={classes.value}>
                          {fortisId}
                        </Typography>
                      </Grid>
                    )}
                    {merchantId && (
                      <Grid item xs={6} display="flex" key={merchantAccount.id}>
                        <Typography className={classes.label}>
                          {t('common.additional-merchant-id')}
                        </Typography>
                        <Typography className={classes.value}>
                          {merchantId}
                        </Typography>
                      </Grid>
                    )}
                  </Fragment>
                )
              })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
