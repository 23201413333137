import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Menu, MenuItem, IconButton } from '@mui/material'

import { useEnforceLogin } from '@shared/hooks'
import mapPortalToModule, { Portal } from '@shared/mapping/portals'

import { WaffleIcon } from '@/assets/icons'

const useStyles = tss.withName('WaffleMenu').create({
  customMenu: {
    width: '300px',
    maxHeight: '400px',
  },
})

export const WaffleMenu = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { user } = useEnforceLogin()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [moduleAccess, setModuleAccess] = useState<number[]>([])
  const [currentPortal, setCurrentPortal] = useState<string>('')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!user) {
      return
    }
    setModuleAccess(user.module_access as number[])
  }, [user])

  useEffect(() => {
    setCurrentPortal(window.location.pathname.split('/')[1])
  }, [window.location.pathname])

  const handleMenuItemClick = (url: string) => {
    handleClose()
    window.open(url, '_blank')
  }

  const renderMenuItem = (label: string, url: string) => {
    return (
      <MenuItem
        key={url}
        onClick={() => handleMenuItemClick(url)}
        data-guiding-id={`appbar-waffle-menu-${label
          .toLowerCase()
          .replace(/ /g, '-')}`}
      >
        {label}
      </MenuItem>
    )
  }

  const renderMenuItems = useMemo(() => {
    if (!moduleAccess) {
      return null
    }
    const menuItems = [1, 2, 3, 4, 5].map((key: number) => {
      if (
        moduleAccess.indexOf(key) !== -1 &&
        mapPortalToModule(currentPortal as Portal) !== key
      ) {
        switch (key) {
          case 1:
            return renderMenuItem(
              t('ftp-app-bar.waffle-menu.portal-merchant'),
              `${process.env.URL_BASE_PATH}/merchant`
            )
          case 2:
            return renderMenuItem(
              t('ftp-app-bar.waffle-menu.portal-partner'),
              `${process.env.URL_BASE_PATH}/partner`
            )
          case 3:
            return renderMenuItem(
              t('ftp-app-bar.waffle-menu.portal-employee'),
              '/'
            )
          case 4:
            return renderMenuItem(
              t('ftp-app-bar.waffle-menu.portal-inventory'),
              '/'
            )
          case 5:
            return renderMenuItem(
              t('ftp-app-bar.waffle-menu.portal-admin'),
              '/'
            )
          default:
            return null
        }
      }

      return null
    })

    return menuItems.filter((item) => item)
  }, [moduleAccess, currentPortal])

  if (!moduleAccess || renderMenuItems.length === 0) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        data-testid="waffle-button"
        data-guiding-id="appbar-waffle"
      >
        <WaffleIcon fontSize="large" data-testid="waffle-icon" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.customMenu }}
        data-testid="menu"
        data-guiding-id="appbar-waffle-menu"
      >
        {renderMenuItems}
      </Menu>
    </>
  )
}
