import SvgIcon from '@mui/material/SvgIcon'

const WaffleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.1263 12.5055C7.26065 12.5055 6.41444 12.2488 5.69469 11.7679C4.97493 11.287 4.41394 10.6034 4.08268 9.80366C3.75141 9.00391 3.66473 8.12388 3.83361 7.27487C4.00249 6.42586 4.41934 5.64599 5.03144 5.03389C5.64355 4.42178 6.42342 4.00493 7.27243 3.83605C8.12144 3.66717 9.00147 3.75385 9.80122 4.08512C10.601 4.41639 11.2845 4.97737 11.7655 5.69713C12.2464 6.41689 12.5031 7.26309 12.5031 8.12874C12.5018 9.28915 12.0403 10.4017 11.2198 11.2222C10.3992 12.0428 9.28671 12.5043 8.1263 12.5055Z"
          fill="#4B5563"
        />
        <path
          d="M20.0008 12.5036C19.1352 12.5036 18.289 12.2469 17.5692 11.7659C16.8494 11.285 16.2885 10.6015 15.9572 9.80171C15.6259 9.00195 15.5392 8.12193 15.7081 7.27292C15.877 6.4239 16.2939 5.64404 16.906 5.03193C17.5181 4.41983 18.2979 4.00298 19.1469 3.8341C19.996 3.66522 20.876 3.7519 21.6757 4.08316C22.4755 4.41443 23.159 4.97542 23.64 5.69517C24.1209 6.41493 24.3776 7.26114 24.3776 8.12678C24.3763 9.2872 23.9148 10.3997 23.0943 11.2203C22.2738 12.0408 21.1612 12.5023 20.0008 12.5036Z"
          fill="#4B5563"
        />
        <path
          d="M31.8758 12.5036C31.0102 12.5036 30.164 12.2469 29.4442 11.7659C28.7244 11.285 28.1635 10.6015 27.8322 9.80171C27.5009 9.00195 27.4142 8.12193 27.5831 7.27292C27.752 6.4239 28.1689 5.64404 28.781 5.03193C29.3931 4.41983 30.1729 4.00298 31.0219 3.8341C31.871 3.66522 32.751 3.7519 33.5507 4.08316C34.3505 4.41443 35.034 4.97542 35.515 5.69517C35.9959 6.41493 36.2526 7.26114 36.2526 8.12678C36.2513 9.2872 35.7898 10.3997 34.9693 11.2203C34.1488 12.0408 33.0362 12.5023 31.8758 12.5036Z"
          fill="#4B5563"
        />
        <path
          d="M8.12581 24.3786C7.26016 24.3786 6.41396 24.1219 5.6942 23.6409C4.97444 23.16 4.41346 22.4765 4.08219 21.6767C3.75092 20.877 3.66424 19.9969 3.83312 19.1479C4.002 18.2989 4.41885 17.519 5.03096 16.9069C5.64306 16.2948 6.42293 15.878 7.27194 15.7091C8.12095 15.5402 9.00098 15.6269 9.80073 15.9582C10.6005 16.2894 11.284 16.8504 11.765 17.5702C12.2459 18.2899 12.5026 19.1361 12.5026 20.0018C12.5013 21.1622 12.0398 22.2747 11.2193 23.0953C10.3988 23.9158 9.28622 24.3773 8.12581 24.3786Z"
          fill="#4B5563"
        />
        <path
          d="M20.0008 24.3786C19.1352 24.3786 18.289 24.1219 17.5692 23.6409C16.8494 23.16 16.2885 22.4765 15.9572 21.6767C15.6259 20.877 15.5392 19.9969 15.7081 19.1479C15.877 18.2989 16.2939 17.519 16.906 16.9069C17.5181 16.2948 18.2979 15.878 19.1469 15.7091C19.996 15.5402 20.876 15.6269 21.6757 15.9582C22.4755 16.2894 23.159 16.8504 23.64 17.5702C24.1209 18.2899 24.3776 19.1361 24.3776 20.0018C24.3763 21.1622 23.9148 22.2747 23.0943 23.0953C22.2738 23.9158 21.1612 24.3773 20.0008 24.3786Z"
          fill="#4B5563"
        />
        <path
          d="M31.8758 24.3786C31.0102 24.3786 30.164 24.1219 29.4442 23.6409C28.7244 23.16 28.1635 22.4765 27.8322 21.6767C27.5009 20.877 27.4142 19.9969 27.5831 19.1479C27.752 18.2989 28.1689 17.519 28.781 16.9069C29.3931 16.2948 30.1729 15.878 31.0219 15.7091C31.871 15.5402 32.751 15.6269 33.5507 15.9582C34.3505 16.2894 35.034 16.8504 35.515 17.5702C35.9959 18.2899 36.2526 19.1361 36.2526 20.0018C36.2513 21.1622 35.7898 22.2747 34.9693 23.0953C34.1488 23.9158 33.0362 24.3773 31.8758 24.3786Z"
          fill="#4B5563"
        />
        <path
          d="M8.12581 36.2536C7.26016 36.2536 6.41396 35.9969 5.6942 35.5159C4.97444 35.035 4.41346 34.3515 4.08219 33.5517C3.75092 32.752 3.66424 31.8719 3.83312 31.0229C4.002 30.1739 4.41885 29.394 5.03096 28.7819C5.64306 28.1698 6.42293 27.753 7.27194 27.5841C8.12095 27.4152 9.00098 27.5019 9.80073 27.8332C10.6005 28.1644 11.284 28.7254 11.765 29.4452C12.2459 30.1649 12.5026 31.0111 12.5026 31.8768C12.5013 33.0372 12.0398 34.1497 11.2193 34.9703C10.3988 35.7908 9.28622 36.2523 8.12581 36.2536Z"
          fill="#4B5563"
        />
        <path
          d="M20.0008 36.2536C19.1352 36.2536 18.289 35.9969 17.5692 35.5159C16.8494 35.035 16.2885 34.3515 15.9572 33.5517C15.6259 32.752 15.5392 31.8719 15.7081 31.0229C15.877 30.1739 16.2939 29.394 16.906 28.7819C17.5181 28.1698 18.2979 27.753 19.1469 27.5841C19.996 27.4152 20.876 27.5019 21.6757 27.8332C22.4755 28.1644 23.159 28.7254 23.64 29.4452C24.1209 30.1649 24.3776 31.0111 24.3776 31.8768C24.3763 33.0372 23.9148 34.1497 23.0943 34.9703C22.2738 35.7908 21.1612 36.2523 20.0008 36.2536Z"
          fill="#4B5563"
        />
        <path
          d="M31.8758 36.2536C31.0102 36.2536 30.164 35.9969 29.4442 35.5159C28.7244 35.035 28.1635 34.3515 27.8322 33.5517C27.5009 32.752 27.4142 31.8719 27.5831 31.0229C27.752 30.1739 28.1689 29.394 28.781 28.7819C29.3931 28.1698 30.1729 27.753 31.0219 27.5841C31.871 27.4152 32.751 27.5019 33.5507 27.8332C34.3505 28.1644 35.034 28.7254 35.515 29.4452C35.9959 30.1649 36.2526 31.0111 36.2526 31.8768C36.2513 33.0372 35.7898 34.1497 34.9693 34.9703C34.1488 35.7908 33.0362 36.2523 31.8758 36.2536Z"
          fill="#4B5563"
        />
      </svg>
    </SvgIcon>
  )
}

export default WaffleIcon
