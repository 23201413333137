import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import { QuickInvoice } from '../../schemas/types'

interface IResendEmail {
  success: boolean
  sms_success: boolean
}

interface IReopen {
  id: string
  success: boolean
}

export class QuickInvoiceService extends ApiService<QuickInvoice> {
  async resendEmail(quickInvoiceId: string) {
    const quickInvoice = await this.apiRequest<IResendEmail>({
      body: {},
      url: `${quickInvoiceId}/resend?email=1`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.emit('updated', quickInvoice)

    return quickInvoice
  }

  async assignTransaction(quickInvoiceId: string, transactionId: string) {
    const quickInvoice = await this.apiRequest<QuickInvoice>({
      body: { transaction_id: transactionId },
      url: `${quickInvoiceId}/transaction`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.emit('updated', quickInvoice)
    return quickInvoice
  }

  async resendSms(quickInvoiceId: string) {
    const quickInvoice = await this.apiRequest<IResendEmail>({
      body: {},
      url: `${quickInvoiceId}/resend?email=0`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.emit('updated', quickInvoice)
    return quickInvoice
  }

  async deleteTransaction(quickInvoiceId: string, transactionId: string) {
    const quickInvoice = await this.apiRequest<QuickInvoice>({
      body: { transaction_id: transactionId },
      url: `${quickInvoiceId}/transaction`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    this.emit('updated', quickInvoice)
    return quickInvoice
  }

  async reopen(quickInvoiceId: string) {
    const quickInvoice = await this.apiRequest<IReopen>({
      body: {},
      url: `${quickInvoiceId}/reopen`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.emit('updated', quickInvoice)

    return quickInvoice
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    'quick-invoices': QuickInvoiceService
  }
}

export const merchantPortalQuickInvoices = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'quick-invoices',
    new QuickInvoiceService(connection.service('quick-invoices'), app)
  )

  const expands = expandQuery(
    'contact',
    'cc_product_transaction',
    'ach_product_transaction',
    'transactions',
    'tags',
    'created_user'
  )

  // Hooks for all methods
  app.service('quick-invoices').hooks({
    around: {
      find: [paginationQuery(), expands],
      get: [expands],
      all: [mapResult()],
      export: [expands],
    },
  })
}
