import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  AppBar,
  Box,
  CssBaseline,
  ThemeProvider,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material'

import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  FtpPortalHubCommunicationProvider,
  ReportFiltersProvider,
  useFtpPortalHubCommunication,
} from '@shared/hooks'
import { ArrowLeftGray } from '@shared/icons'
import { FtpSetAppBarTitleEvent, FtpSetAppShowSearchEvent } from '@shared/types'

import { ProfileDropdown, Search, WaffleMenu } from '@/components'

import CustomBreadcrumbs from './components/CustomBreadcrumbs/CustomBreadcrumbs'
import i18n from './i18n'

const useStyles = tss.withName('FtpTopHeader').create({
  toolbar: {
    paddingTop: 30,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
  },
})

const FtpTopHeader = () => {
  const { classes } = useStyles()
  const { clearSearchButton } = useFtpPortalHubCommunication()
  const [title, setTitle] = useState<string>('')
  const [infoText, setInfoText] = useState<string>('')
  const [breadcrumbs, setBreadcrumbs] = useState<Array<string> | string>()
  const [showSearchBar, setShowSearchBar] = useState<boolean>()
  const [backUrl, setBackUrl] = useState<string | 'back'>('')

  useEffect(() => {
    const setNewTitle = (newTitleEvent: FtpSetAppBarTitleEvent) => {
      setTitle(newTitleEvent.title)
      setInfoText(newTitleEvent?.infoText ?? undefined)
      setBreadcrumbs(newTitleEvent.breadcrumbs ?? undefined)
      setBackUrl(newTitleEvent.backUrl ?? undefined)
    }

    const setNewShowSearchBar = (newShowSearch: FtpSetAppShowSearchEvent) => {
      setShowSearchBar(newShowSearch.showSearchBar)
    }

    window.addEventListener(
      'FTP_APPBAR_SET_TITLE',
      setNewTitle as EventListener
    )

    window.addEventListener(
      'FTP_APPBAR_SET_SEARCH',
      setNewShowSearchBar as EventListener
    )

    const appBarLoadedEvent = new Event('FTP_APPBAR_LOADED')
    window.dispatchEvent(appBarLoadedEvent)

    return () => {
      window.removeEventListener('FTP_APPBAR_SET_TITLE', setNewTitle)
      window.removeEventListener('FTP_APPBAR_SET_SEARCH', setNewShowSearchBar)
    }
  }, [])

  const handleClearSearch = () => {
    clearSearchButton()
  }

  const navigateBack = () => {
    navigateToUrl(backUrl)
  }

  return (
    <div>
      <CssBaseline />
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <Box sx={{ width: '100%' }}>
            <CustomBreadcrumbs breadcrumbs={breadcrumbs} />
            <Box display={'flex'}>
              {backUrl && (
                <IconButton
                  sx={{ padding: 0, marginRight: '8px' }}
                  disableRipple
                  onClick={navigateBack}
                  data-guiding-id="appbar-arrowback"
                >
                  <ArrowLeftGray />
                </IconButton>
              )}

              <Typography
                fontFamily={'Inter'}
                fontWeight={600}
                fontSize={24}
                color="neutral-900"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {title}
                {infoText !== undefined && (
                  <Tooltip title={infoText} data-guiding-id="appbar-tooltip">
                    <IconButton onClick={handleClearSearch}>
                      <InfoOutlinedIcon color="disabled" fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actionsContainer}>
            {!showSearchBar ? null : <Search />}
            <WaffleMenu />
            <ProfileDropdown />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default function FtpAppBar() {
  return (
    <ThemeProvider theme={AppTheme}>
      <I18nextProvider i18n={i18n}>
        <ReportFiltersProvider>
          <FtpPortalHubCommunicationProvider>
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <EnforceLoginProvider>
                <FtpTopHeader />
              </EnforceLoginProvider>
            </ErrorBoundary>
          </FtpPortalHubCommunicationProvider>
        </ReportFiltersProvider>
      </I18nextProvider>
    </ThemeProvider>
  )
}
