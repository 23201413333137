import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export const ArrowLeftGray = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.52398 9.16591H16.6673V10.8326H6.52398L10.994 15.3026L9.81565 16.4809L3.33398 9.99925L9.81565 3.51758L10.994 4.69591L6.52398 9.16591Z"
          fill="#6B7280"
        />
      </svg>
    </SvgIcon>
  )
}
