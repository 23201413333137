import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Close } from '@mui/icons-material'
import {
  Typography,
  Button,
  Box,
  IconButton,
  DialogContent,
  Dialog,
  Grid,
  DialogActions,
  DialogTitle,
} from '@mui/material'

import {
  QuickInvoice,
  Recurring,
  Tags,
  Transaction,
  api,
} from '@shared/api/src'
import {
  Loading,
  TransactionStatus,
  Transition,
  AccountType,
  EmailReceiptModal,
  SurchargeDisclosure,
  FieldGroupViewItem,
  FieldGroupViewItemWithChildren,
  PageLayoutDivider,
} from '@shared/components'
import AppTheme from '@shared/design'
import { useAuthorization, useNotification } from '@shared/hooks'
import {
  codeToEntryMode,
  codeToReason,
  mapTransactionTypeId,
} from '@shared/mapping/gateway-transactions'
import {
  PaymentMethodType,
  GatewayTransactionEntryModeKey,
} from '@shared/types'
import {
  currency,
  formatDatetime,
  calculateSubtotal,
  checkPermission,
} from '@shared/utils'

import ContactInfoCard from '../../merchant/info-cards/contact-info-card/ContactInfoCard'
import QuickInvoiceInfoCard from '../../merchant/info-cards/quick-invoice-info-card/QuickInvoiceInfoCard'
import RecurringBillingInfoCard from '../../merchant/info-cards/recurring-billing-info-card/RecurringBillingInfoCard'
import TagList from '../../merchant/tag-list/TagList'
import { UnassignTransactionModal } from '../../unassign-transaction-modal/UnassignTransactionModal'
import VoidTransactionModal from '../void-transaction/VoidTransactionModal'

interface TransactionDetailsProps {
  onClose: () => void
  transactionId: string
  tz: string
  isModalOpen?: boolean
  screen?: string
  onTransactionChange?: () => void
  onReprintTerminal?: (transactionId: string) => void
}

const useStyles = tss
  .withName('TransactionDetailsModal')
  .create(({ theme }) => ({
    buttonsContainer: {
      width: '100%',
      zIndex: 1,
      borderTop: `1px solid ${theme.palette.divider}`,
      background: theme.palette.background.paper,
    },
    label: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#4B5563',
    },
    valueLabel: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#111827',
    },
    amountLabel: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 'bold',
    },
    closeButton: {
      position: 'absolute',
      right: 0,
    },
  }))

const TransactionDetailsModal: React.FC<TransactionDetailsProps> = ({
  onClose,
  transactionId,
  tz,
  isModalOpen = false,
  onTransactionChange,
  onReprintTerminal,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()

  const [openEmailReceipt, setOpenEmailReceipt] = useState<boolean>(false)
  const [transaction, setTransaction] = useState<Transaction>()
  const [loading, setLoading] = useState<boolean>(false)
  const [openVoidTransaction, setOpenVoidTransaction] = useState<boolean>(false)
  const [isUnassignTransactionModalOpen, setIsUnassignTransactionModalOpen] =
    useState<boolean>(false)

  const handleClose = () => {
    setTransaction(undefined)
    onClose()
  }

  const getTransaction = async (id: string) => {
    try {
      setLoading(true)
      const transaction = await api.service('transactions').get(id)
      setTransaction(transaction)
    } catch (error) {
      setNotification({
        type: 'error',
        label: t('common.toast.transaction.not-found'),
      })
      handleClose()
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (isModalOpen && transactionId) {
      getTransaction(transactionId)
    }
  }, [isModalOpen, transactionId])

  const showTip =
    transaction?.tip_amount != null &&
    transaction?.payment_method !== 'ach' &&
    transaction?.product_transaction?.vt_enable_tip

  const showSalesTax =
    transaction?.tax != null &&
    transaction?.product_transaction?.vt_enable_sales_tax

  const showSurcharge = transaction?.surcharge != null

  const showSubtotal = showTip || showSalesTax || showSurcharge

  const handlePrintReceipt = useCallback((transactionId) => {
    const printUrl = `/print/transaction/${transactionId}`
    window.open(printUrl, '_blank', 'noreferrer')
  }, [])

  const onVoidSuccess = useCallback(() => {
    setNotification({
      type: 'success',
      label: t('common.toast.transaction.void-success'),
    })

    getTransaction(transactionId)
    onTransactionChange?.()
  }, [transactionId])

  const onVoidError = () => {
    setNotification({
      type: 'error',
      label: t('common.toast.transaction.could-not-be-voided'),
    })
  }

  return (
    <>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: 'absolute',
            minWidth: '400px',
            maxWidth: '448px',
            height: '100%',
            right: 0,
            top: 0,
            margin: 0,
            padding: 0,
          },
        }}
        aria-labelledby="transaction-details-dialog"
        data-guiding-id="transactiondetails-modal"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            position="sticky"
            top={0}
            zIndex={1}
            bgcolor="background.paper"
            marginBottom="12px"
          >
            <Typography variant="h6">
              {t('common.transaction-details')}
            </Typography>
            <IconButton
              onClick={handleClose}
              className={classes.closeButton}
              aria-label="close"
              data-guiding-id="transactiondetails-modal-close"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            padding: '24px',
          }}
        >
          {loading || !transaction ? (
            <Loading />
          ) : (
            <>
              <Grid container rowGap="10px">
                <FieldGroupViewItemWithChildren
                  title={t('common.amount-transaction')}
                  isFullWidth
                  isValueAlignRight
                  isVerticallyCentered
                >
                  <Typography
                    variant="body2"
                    className={classes.amountLabel}
                    style={{
                      marginBottom:
                        Boolean(transaction.auth_amount) &&
                        transaction.auth_amount !==
                          transaction.transaction_amount
                          ? '14px'
                          : '0',
                    }}
                  >
                    {currency(transaction.transaction_amount, true)}
                  </Typography>
                </FieldGroupViewItemWithChildren>

                {Boolean(transaction.auth_amount) &&
                  transaction.auth_amount !==
                    transaction.transaction_amount && (
                    <FieldGroupViewItem
                      title={t('common.amount-auth')}
                      value={currency(transaction.auth_amount, true)}
                      isFullWidth
                      isValueAlignRight
                    />
                  )}
              </Grid>

              <PageLayoutDivider />

              <Grid container rowGap="10px">
                {showSubtotal && (
                  <FieldGroupViewItem
                    title={t('common.amount-subtotal')}
                    value={currency(calculateSubtotal(transaction), true)}
                    isFullWidth
                    isValueAlignRight
                  />
                )}
                {showSurcharge && (
                  <FieldGroupViewItem
                    title={t('common.amount-surcharge')}
                    value={currency(
                      transaction.surcharge.surcharge_amount as number,
                      true
                    )}
                    isFullWidth
                    isValueAlignRight
                  />
                )}
                {showSalesTax && (
                  <FieldGroupViewItem
                    title={t('common.tax')}
                    value={currency(transaction.tax, true)}
                    isFullWidth
                    isValueAlignRight
                  />
                )}
                {showTip && (
                  <FieldGroupViewItem
                    title={t('common.tip')}
                    value={currency(transaction.tip_amount, true)}
                    isFullWidth
                    isValueAlignRight
                  />
                )}
              </Grid>

              {showSubtotal && <PageLayoutDivider />}

              <Grid container rowGap="10px">
                <FieldGroupViewItem
                  title={`${t('common.date')} / ${t('common.time')}`}
                  value={formatDatetime(transaction.created_ts, tz)}
                  isFullWidth
                  isValueAlignRight
                />
                <FieldGroupViewItemWithChildren
                  title={t('common.transaction-status')}
                  isFullWidth
                  isValueAlignRight
                  isVerticallyCentered
                >
                  <TransactionStatus statusCode={transaction.status_code} />
                </FieldGroupViewItemWithChildren>
                <FieldGroupViewItem
                  title={t('common.transaction-type')}
                  value={
                    transaction.type_id
                      ? mapTransactionTypeId(transaction.type_id)
                      : t('common.unknown')
                  }
                  isFullWidth
                  isValueAlignRight
                />
                {(transaction.payment_method as PaymentMethodType) !==
                  PaymentMethodType.CASH && (
                  <>
                    <FieldGroupViewItem
                      title={t('common.verbiage')}
                      value={transaction.verbiage}
                      isFullWidth
                      isValueAlignRight
                    />

                    <FieldGroupViewItem
                      title={t('common.reason-description')}
                      value={
                        transaction.reason_code_id
                          ? codeToReason(
                              transaction.reason_code_id.toString(),
                              transaction.payment_method
                            )
                          : t('common.unknown')
                      }
                      isFullWidth
                      isValueAlignRight
                    />

                    {transaction.payment_method !== PaymentMethodType.ACH &&
                      parseInt(transaction?.auth_code) > -1 && (
                        <FieldGroupViewItem
                          title={t('common.auth-code')}
                          value={transaction.auth_code}
                          isFullWidth
                          isValueAlignRight
                        />
                      )}
                  </>
                )}
                {transaction.payment_method !== PaymentMethodType.ACH &&
                  Boolean(transaction.product_transaction?.display_avs) && (
                    <>
                      <FieldGroupViewItem
                        title={t('common.avs')}
                        value={transaction.avs ?? ''}
                        isFullWidth
                        isValueAlignRight
                      />
                      <FieldGroupViewItem
                        title={t('common.avs-response')}
                        value={transaction.avs_enhanced}
                        isFullWidth
                        isValueAlignRight
                      />
                    </>
                  )}
              </Grid>

              <PageLayoutDivider />

              <Grid container rowGap="10px">
                <FieldGroupViewItemWithChildren
                  title={t('common.payment-method')}
                  isFullWidth
                  isValueAlignRight
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {(transaction.payment_method as PaymentMethodType) !==
                    PaymentMethodType.CASH ? (
                      <>
                        <AccountType type={transaction.account_type} />-
                        <Typography
                          variant="body2"
                          className={classes.valueLabel}
                          noWrap
                        >
                          {transaction.last_four ?? '0000'}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        variant="body2"
                        className={classes.label}
                        noWrap
                      >
                        {t('common.cash')}
                      </Typography>
                    )}
                  </Box>
                </FieldGroupViewItemWithChildren>

                {(transaction.payment_method as PaymentMethodType) !==
                  PaymentMethodType.CASH && (
                  <>
                    <FieldGroupViewItem
                      title={t('common.account-holder-name')}
                      value={transaction.account_holder_name}
                      isFullWidth
                      isValueAlignRight
                    />

                    <FieldGroupViewItem
                      title={t('common.entry-mode')}
                      value={
                        transaction.entry_mode_id
                          ? codeToEntryMode(
                              transaction.entry_mode_id.toString() as GatewayTransactionEntryModeKey
                            )
                          : t('common.unknown')
                      }
                      isFullWidth
                      isValueAlignRight
                    />
                  </>
                )}
              </Grid>

              <PageLayoutDivider />

              <Grid container rowGap="10px">
                {(transaction.payment_method as PaymentMethodType) !==
                  PaymentMethodType.CASH && (
                  <>
                    {transaction.product_transaction?.vt_order_num && (
                      <FieldGroupViewItem
                        title={t('common.order-number')}
                        value={transaction.order_number}
                        isFullWidth
                        isValueAlignRight
                      />
                    )}
                    {transaction.product_transaction?.vt_clerk_number && (
                      <FieldGroupViewItem
                        title={t('common.clerk-number')}
                        value={transaction.clerk_number}
                        isFullWidth
                        isValueAlignRight
                      />
                    )}
                  </>
                )}
                {Boolean(
                  transaction.product_transaction?.receipt_show_custom_fields
                ) && (
                  <>
                    <FieldGroupViewItem
                      title={t('common.custom-1')}
                      value={transaction.transaction_c1}
                      isFullWidth
                      isValueAlignRight
                    />
                    <FieldGroupViewItem
                      title={t('common.custom-2')}
                      value={transaction.transaction_c2}
                      isFullWidth
                      isValueAlignRight
                    />
                    <FieldGroupViewItem
                      title={
                        transaction.product_transaction?.partner?.toLowerCase() ===
                          'vericle' ||
                        transaction.product_transaction?.partner?.toLowerCase() ===
                          'vericle-statement'
                          ? t('common.date-service')
                          : t('common.custom-3')
                      }
                      value={transaction.transaction_c3}
                      isFullWidth
                      isValueAlignRight
                    />
                  </>
                )}
                {transaction.tags && (
                  <FieldGroupViewItemWithChildren
                    title={t('common.tags')}
                    isFullWidth
                    isValueAlignRight
                  >
                    <TagList
                      items={(transaction.tags as Tags[]) ?? []}
                      nameSelector={(tag: Tags) => tag.title}
                      wrapWords={false}
                    />
                  </FieldGroupViewItemWithChildren>
                )}
                <FieldGroupViewItem
                  title={t('common.description')}
                  value={transaction.description}
                  isFullWidth
                  isValueAlignRight
                />
              </Grid>

              <PageLayoutDivider />

              <Grid container rowGap="10px">
                <FieldGroupViewItem
                  title={t('common.transaction-id')}
                  value={transaction.id}
                  isFullWidth
                  isValueAlignRight
                />

                {!!transaction.product_transaction?.surcharge?.compliant &&
                  (transaction.surcharge?.surcharge_amount as number) > 0 && (
                    <SurchargeDisclosure
                      merchantAccount={transaction.product_transaction}
                      containerStyle={{
                        padding: '16px 0',
                      }}
                    />
                  )}
              </Grid>

              {(!!transaction?.contact ||
                !!transaction.recurring_id ||
                !!transaction.quick_invoice_id) && <PageLayoutDivider />}

              {transaction.contact ? (
                <Box>
                  <ContactInfoCard contact={transaction.contact} />
                </Box>
              ) : null}

              {Boolean(transaction.recurring_id) && (
                <>
                  <Box>
                    <RecurringBillingInfoCard
                      recurringInfo={
                        transaction.recurring as unknown as Recurring
                      }
                      tz={tz}
                    />
                  </Box>
                </>
              )}

              {Boolean(transaction.quick_invoice_id) && (
                <Box>
                  <QuickInvoiceInfoCard
                    quickInvoiceInfo={
                      transaction.quick_invoice as unknown as QuickInvoice
                    }
                    tz={tz}
                    onUnassignTransaction={() =>
                      setIsUnassignTransactionModalOpen(true)
                    }
                    showButtonUnassignTransaction={checkPermission(
                      userPermissionSet,
                      'v2.quickinvoices.put.removetransaction'
                    )}
                  />
                </Box>
              )}

              <PageLayoutDivider />

              <Box display="flex" flexDirection="column" width="100%">
                <Typography variant="h6" gutterBottom>
                  {t('common.transaction-history')}
                </Typography>
                {transaction?.transaction_histories &&
                transaction.transaction_histories.length > 0
                  ? transaction.transaction_histories.map((historyItem) => (
                      <FieldGroupViewItemWithChildren
                        key={historyItem.id}
                        title={formatDatetime(historyItem?.event_date_ts, tz)}
                        isFullWidth
                        isValueAlignRight
                        isVerticallyCentered
                      >
                        <TransactionStatus
                          statusCode={
                            historyItem.status_code as Transaction['status_code']
                          }
                        />
                      </FieldGroupViewItemWithChildren>
                    ))
                  : null}
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {!loading && transaction && (
            <Box className={classes.buttonsContainer}>
              {transaction.is_voidable &&
                checkPermission(
                  userPermissionSet,
                  'v2.transactions.put.void'
                ) && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      backgroundColor: '#00263A',
                      marginBottom: '10px',
                      '&:hover': {
                        backgroundColor: '#00263A',
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => setOpenVoidTransaction(true)}
                    data-guiding-id="transactiondetails-modal-void"
                  >
                    {t('common.action.transaction.void')}
                  </Button>
                )}
              <Button
                onClick={() => setOpenEmailReceipt(true)}
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: '#00263A',
                  marginBottom: '10px',
                  '&:hover': {
                    backgroundColor: '#00263A',
                    opacity: 0.8,
                  },
                }}
                data-guiding-id="transactiondetails-modal-emailreceipt"
              >
                {t('common.action.transaction.email-receipt')}
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePrintReceipt(transaction.id)}
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: '#00263A',
                  marginBottom: '10px',
                  '&:hover': {
                    backgroundColor: '#00263A',
                    opacity: 0.8,
                  },
                }}
                data-guiding-id="transactiondetails-modal-printreceipt"
              >
                {t('common.print-receipt')}
              </Button>
              {transaction?.terminal?.print_enable &&
                transaction?.terminal?.terminal_manufacturer_code.toString() !==
                  '1' && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      backgroundColor: '#00263A',
                      marginBottom: '10px',
                      '&:hover': {
                        backgroundColor: '#00263A',
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => onReprintTerminal(transaction.id)}
                    data-guiding-id="transactiondetails-modal-reprintonterminal"
                  >
                    {t('common.action.transaction.reprint-on-terminal')}
                  </Button>
                )}
            </Box>
          )}
        </DialogActions>
      </Dialog>

      {transaction && (
        <>
          <EmailReceiptModal
            transaction={transaction}
            open={openEmailReceipt}
            onClose={() => setOpenEmailReceipt(false)}
          />

          <VoidTransactionModal
            transaction={transaction}
            open={openVoidTransaction}
            onClose={() => setOpenVoidTransaction(false)}
            onSuccess={onVoidSuccess}
            onError={onVoidError}
          />

          {!!transaction.quick_invoice && (
            <UnassignTransactionModal
              quickInvoice={
                transaction.quick_invoice as unknown as QuickInvoice
              }
              transaction={transaction}
              open={isUnassignTransactionModalOpen}
              onClose={() => setIsUnassignTransactionModalOpen(false)}
              onSuccess={() => {
                getTransaction(transactionId)
                onTransactionChange?.()
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default TransactionDetailsModal
