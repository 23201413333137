import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import { QuickInvoice } from '@shared/api/src'
import { PaymentStatus } from '@shared/components'
import AppTheme from '@shared/design'
import { formatDate, currency } from '@shared/utils'

interface QuickInvoiceInfoCardProps {
  quickInvoiceInfo: QuickInvoice
  tz: string
  title?: string
  labelStyle?: React.CSSProperties
  showButtonUnassignTransaction?: boolean
  showButtonViewQuickInvoice?: boolean
  showTotalAmountDue?: boolean
  onUnassignTransaction?: () => void
}

const useStyles = tss.withName('QuickInvoiceInfoCard').create(() => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    columnGap: '12px',
  },
  label: {
    fontSize: '14px',
    color: '#757575',
  },
  titleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    background: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    color: '#4a5568',
    '&:hover': {
      background: '#E0E0E0',
      borderColor: '#E0E0E0',
    },
  },
  viewButton: {
    padding: '3px 10px',
  },
  unassignButton: {
    padding: '6px 16px',
    marginRight: 'auto',
    marginTop: '1rem',
  },
}))

export const QuickInvoiceInfoCard: React.FC<QuickInvoiceInfoCardProps> = ({
  quickInvoiceInfo,
  tz,
  title,
  labelStyle,
  showButtonUnassignTransaction = true,
  showButtonViewQuickInvoice = true,
  showTotalAmountDue = false,
  onUnassignTransaction,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const {
    payment_status_id,
    invoice_number,
    due_date,
    expire_date,
    amount_due,
    remaining_balance,
  } = quickInvoiceInfo

  const dueDateFormatted =
    due_date && due_date !== '0000-00-00' ? formatDate(due_date, 'UTC') : ''
  const expirationDateFormatted =
    expire_date && expire_date !== '0000-00-00'
      ? formatDate(expire_date, 'UTC')
      : ''

  const itemData = [
    {
      label: t('common.payment-status'),
      info: <PaymentStatus statusCode={payment_status_id} />,
    },
    invoice_number && {
      label: t('common.invoice-number'),
      info: (
        <ListItemText
          secondary={invoice_number}
          secondaryTypographyProps={{
            variant: 'body2',
            color: 'textPrimary',
          }}
          style={{
            wordBreak: 'break-word',
          }}
        />
      ),
    },
    due_date && {
      label: t('common.due-date'),
      info: (
        <ListItemText
          secondary={dueDateFormatted}
          secondaryTypographyProps={{
            variant: 'body2',
            color: 'textPrimary',
          }}
        />
      ),
    },
    expire_date && {
      label: t('common.expiration-date'),
      info: (
        <ListItemText
          secondary={expirationDateFormatted}
          secondaryTypographyProps={{
            variant: 'body2',
            color: 'textPrimary',
          }}
        />
      ),
    },
    showTotalAmountDue &&
      amount_due && {
        label: t('common.total-amount-due'),
        info: (
          <ListItemText
            secondary={currency(amount_due / 100 ?? 0)}
            secondaryTypographyProps={{
              variant: 'body2',
              color: 'textPrimary',
            }}
          />
        ),
      },
    remaining_balance && {
      label: t('common.remaining-balance'),
      info: (
        <ListItemText
          secondary={currency(remaining_balance / 100 ?? 0)}
          secondaryTypographyProps={{
            variant: 'body2',
            color: 'textPrimary',
          }}
        />
      ),
    },
  ].filter(Boolean)

  const urlView = `/merchant/gateway/quick-invoice/${quickInvoiceInfo.id}/view`
  showButtonViewQuickInvoice =
    showButtonViewQuickInvoice && urlView !== window.location.pathname
  const onViewDetails = () => {
    // navigate(urlView)
    window.open(urlView, '_blank')
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor="rgb(243, 244, 246)"
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      p={2}
      my={2}
    >
      <Box className={classes.titleContent}>
        <Typography variant="h6" className={classes.title}>
          {title || t('common.quick-invoice')}
        </Typography>
        {showButtonViewQuickInvoice ? (
          <Button
            variant="outlined"
            onClick={onViewDetails}
            className={`${classes.button} ${classes.viewButton}`}
            data-guiding-id="quickinvoice-infocard-view"
          >
            {t('common.view')}
          </Button>
        ) : null}
      </Box>
      <List disablePadding>
        {itemData.length &&
          itemData?.map(({ label, info }, index) => (
            <ListItem key={index} disableGutters className={classes.listItem}>
              <Typography
                variant="caption"
                className={classes.label}
                style={labelStyle}
              >
                {label}
              </Typography>
              <Box>{info}</Box>
            </ListItem>
          ))}
      </List>
      {showButtonUnassignTransaction ? (
        <Button
          variant="outlined"
          onClick={onUnassignTransaction}
          className={`${classes.button} ${classes.unassignButton}`}
          data-guiding-id="quickinvoice-infocard-unassign"
        >
          Unassign Transaction
        </Button>
      ) : null}
    </Box>
  )
}

export default QuickInvoiceInfoCard
