import { adminAuthRoles } from './admin-auth-roles.service'
import { applicationsSubmittedByMonth } from './applications-submitted-by-month.service'
import { applications } from './applications.service'
import { authRoleUsers } from './auth-role-users.service'
import { calculateSurcharge } from './calculate-surcharge.service'
import { commissions } from './commissions.service'
import { dashboardMonthlyResiduals } from './dashboard-monthly-residuals.service'
import { dashboardSettledTransactions } from './dashboard-settled-transactions'
import { locationUsers } from './location-users.service'
import { merchantPortalAchRejectsReports } from './merchant-portal/ach-rejects-reports.service'
import { merchantPortalDashboardAchRejects } from './merchant-portal/ach-rejects.service'
import { asyncStatusService } from './merchant-portal/asyncStatus.service'
import { batchesdetails } from './merchant-portal/batches-details.service'
import { batches } from './merchant-portal/batches.service'
import { merchantPortalChargebackReports } from './merchant-portal/chargebacks.reports.service'
import { merchantPortalDashboardChargebacks } from './merchant-portal/chargebacks.service'
import { merchantPortalContactSearches } from './merchant-portal/contact-searches.service'
import { merchantPortalContacts } from './merchant-portal/contacts.service'
import { merchantPortalDashboardSettledTransactionsACH } from './merchant-portal/dashboard-settled-ach-transactions.service'
import { merchantPortalDashboardSettledTransactionsCC } from './merchant-portal/dashboard-settled-cc-transactions.service'
import { merchantPortalDepositsReports } from './merchant-portal/deposits-reports.service'
import { eftRoutingNumbers } from './merchant-portal/eft-routing-numbers.service'
import { merchantPortalDashboardGatewayTransactionsACH } from './merchant-portal/gateway-ach-transactions.service'
import { merchantPortalDashboardGatewayTransactionsCC } from './merchant-portal/gateway-cc-transactions.service'
import { locationAdmin } from './merchant-portal/location-admin.service'
import { locationInfo } from './merchant-portal/location-info.service'
import { locationSearches } from './merchant-portal/location-searches.service'
import { locations } from './merchant-portal/locations.service'
import { merchantPortalMerchantAccounts } from './merchant-portal/merchant-accounts.service'
import { paylinks } from './merchant-portal/paylinks.service'
import { productTransactionAutoSettles } from './merchant-portal/product-transaction-auto-settles'
import { productTransactionDetails } from './merchant-portal/product-transaction-details'
import { merchantPortalQuickInvoices } from './merchant-portal/quick-invoices.service'
import { recurringBillingDeclinesReports } from './merchant-portal/recurring-billing-declines-reports.service'
import { merchantPortalDashboardRecurringBillingDeclines } from './merchant-portal/recurring-billing-declines.service'
import { merchantPortaRecurringBillings } from './merchant-portal/recurring-billings.service'
import { merchantPortalDashboardRecurringBillingForecast } from './merchant-portal/recurring-transactions-forecast.service'
import { merchantPortalDashboardRecurringBillingHistory } from './merchant-portal/recurring-transactions-history.service'
import { routingNumbers } from './merchant-portal/routing-numbers.service'
import { salesTaxes } from './merchant-portal/sales-tax.service'
import { merchantPortalReportingSettledTransactionsCC } from './merchant-portal/settled-cc-transactions.service'
import { merchantPortalSettledTransactionsAch } from './merchant-portal/settled-transactions-ach.service'
import { statements } from './merchant-portal/statements.service'
import { tags } from './merchant-portal/tags.service'
import { Terminals } from './merchant-portal/terminals.service'
import { tokenImportDetails } from './merchant-portal/token-import-details.service'
import { tokenImportMappings } from './merchant-portal/token-import-mappings.service'
import { tokenImport } from './merchant-portal/token-imports.service'
import { merchantPortalTokens } from './merchant-portal/tokens.service'
import { transactionsService } from './merchant-portal/transactions.service'
import { merchants } from './merchants.service'
import { notifications } from './notifications.service'
import { partnerPortalAgents } from './partner-portal/agents.service'
import { passwordResetCode } from './password-reset-code.service'
import { productRecurring } from './product-recurrings.service'
import { quickInvoiceSettings } from './quick-invoice-settings.service'
import { termsAndConditions } from './terms-conditions.service'
import { userPrefSubscribes } from './user-pref-subscribes.service'
import { userPrefs } from './user-prefs.service'
import { userVerification } from './user-verification.service'
import { users } from './users.service'
import { App } from '../commons'

export const services = (app: App) => {
  app.configure(applications)
  app.configure(applicationsSubmittedByMonth)
  app.configure(commissions)
  app.configure(dashboardMonthlyResiduals)
  app.configure(dashboardSettledTransactions)
  app.configure(merchants)
  app.configure(users)
  app.configure(merchantPortalTokens)
  app.configure(merchantPortalReportingSettledTransactionsCC)
  app.configure(passwordResetCode)
  app.configure(termsAndConditions)
  app.configure(transactionsService)
  app.configure(locationAdmin)
  app.configure(asyncStatusService)
  app.configure(locations)
  app.configure(locationInfo)
  app.configure(tags)
  app.configure(locationSearches)
  app.configure(statements)
  app.configure(batches)
  app.configure(merchantPortalDashboardSettledTransactionsACH)
  app.configure(merchantPortalDashboardSettledTransactionsCC)
  app.configure(merchantPortalDashboardGatewayTransactionsACH)
  app.configure(merchantPortalDashboardGatewayTransactionsCC)
  app.configure(merchantPortalDashboardRecurringBillingForecast)
  app.configure(merchantPortalDashboardRecurringBillingHistory)
  app.configure(merchantPortalDashboardRecurringBillingDeclines)
  app.configure(merchantPortalDashboardChargebacks)
  app.configure(merchantPortalDepositsReports)
  app.configure(merchantPortalChargebackReports)
  app.configure(merchantPortalDashboardAchRejects)
  app.configure(userPrefs)
  app.configure(userPrefSubscribes)
  app.configure(productRecurring)
  app.configure(quickInvoiceSettings)
  app.configure(merchantPortalContacts)
  app.configure(merchantPortalContactSearches)
  app.configure(merchantPortalSettledTransactionsAch)
  app.configure(merchantPortaRecurringBillings)
  app.configure(notifications)
  app.configure(merchantPortalQuickInvoices)
  app.configure(merchantPortalMerchantAccounts)
  app.configure(merchantPortalAchRejectsReports)
  app.configure(recurringBillingDeclinesReports)
  app.configure(routingNumbers)
  app.configure(eftRoutingNumbers)
  app.configure(calculateSurcharge)
  app.configure(salesTaxes)
  app.configure(Terminals)
  app.configure(tokenImport)
  app.configure(tokenImportDetails)
  app.configure(tokenImportMappings)
  app.configure(productTransactionDetails)
  app.configure(productTransactionAutoSettles)
  app.configure(authRoleUsers)
  app.configure(adminAuthRoles)
  app.configure(locationUsers)
  app.configure(userVerification)
  app.configure(partnerPortalAgents)
  app.configure(batchesdetails)
  app.configure(paylinks)
}
