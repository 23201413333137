import { forwardRef } from 'react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { MaskedInput, IMaskedInputProps } from './MaskedInput'

type StylesParams = {
  showDollarSign: boolean
}

const useStyles = tss
  .withName('CurrencyInput')
  .withParams<StylesParams>()
  .create(({ theme, showDollarSign }) => ({
    root: {
      position: 'relative',
    },
    input: {
      paddingLeft: showDollarSign ? '2em' : undefined,
    },
    dollarSign: {
      zIndex: 1,
      color: theme.palette['grey'][600],
    },
    currency: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#6B7280',
    },
  }))

interface CurrencyInputProps
  extends Pick<
    IMaskedInputProps,
    | 'label'
    | 'required'
    | 'onChange'
    | 'disabled'
    | 'icon'
    | 'error'
    | 'helperText'
    | 'className'
    | 'testId'
    | 'onBlur'
  > {
  currency?: string
  allowDecimal?: boolean
  showDollarSign?: boolean
  guidingId?: string
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      currency,
      allowDecimal = true,
      showDollarSign = false,
      guidingId,
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles({ showDollarSign })

    return (
      <Box className={classes.root} ref={ref}>
        <MaskedInput
          {...props}
          className={cx(classes.input, props.className)}
          placeholder={showDollarSign ? '0.00' : '$ 0.00'}
          data-guiding-id={guidingId}
          mask={createNumberMask({
            prefix: '',
            allowDecimal,
          })}
          onFocus={(e) => e.target.select()}
          icon={{
            component: currency ? (
              <Typography className={classes.currency}>{currency}</Typography>
            ) : undefined,
          }}
          startAdornment={
            showDollarSign && (
              <Typography className={classes.dollarSign}>$</Typography>
            )
          }
        />
      </Box>
    )
  }
)
