import SvgIcon from '@mui/material/SvgIcon'

const UserIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5 20.0016C5 17.5016 7 15.6016 9.4 15.6016H14.5C17 15.6016 18.9 17.6016 18.9 20.0016"
          stroke="#D64123"
          strokeWidth="1.4824"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 5.2C16.7 6.9 16.7 9.6 15 11.2C13.3 12.8 10.6 12.9 8.99999 11.2C7.39999 9.5 7.29999 6.8 8.99999 5.2C10.7 3.6 13.3 3.6 15 5.2"
          stroke="#D64123"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default UserIcon
