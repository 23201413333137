import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
import { tss } from 'tss-react/mui'

import {
  Box,
  inputBaseClasses,
  outlinedInputClasses,
  TextField,
  Typography,
} from '@mui/material'

import { createNumberMask } from '@shared/utils'

type StylesParams = {
  disabled: boolean
}

const useStyles = tss
  .withName('Input')
  .withParams<StylesParams>()
  .create(({ theme, disabled }) => ({
    label: {
      color: `${theme?.palette['neutral-700']} !important`,
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
    },
    required: {
      color: `${theme?.palette['negative']} !important`,
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
      marginLeft: '3px !important',
    },
    input: {
      borderRadius: '6px',
      [`& .${inputBaseClasses.root}`]: {
        padding: '0 !important',
      },
      [`& .${inputBaseClasses.input}`]: {
        borderRadius: disabled ? '6px' : undefined,
      },
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        border: disabled ? 'none' : undefined,
      },
      '& input': {
        background: disabled ? '#F3F4F6' : undefined,
        padding: '12px 0 12px 12px !important',
        fontFamily: 'Inter !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important',
      },
      '& label.Mui-focused': {
        color: theme.palette['neutral-700'],
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette['neutral-300'],
        borderWidth: '1px',
      },
    },
    endAdornmentText: {
      position: 'absolute',
      right: '16px',
    },
    helperText: {
      marginLeft: '0 !important',
      fontFamily: 'Inter !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      lineHeight: '16px !important',
      color: `${theme?.palette['neutral-500']} !important`,
    },
    helperTextError: {
      color: `${theme?.palette['negative']} !important`,
    },
  }))

export interface InputProps {
  label: string
  value?: string
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  endAdornmentText?: string
  style?: React.CSSProperties
  testId?: string
  inputRef?: React.Ref<any>
  autoFocus?: boolean
  guidingId?: string
}

const InputIntegerOnly = forwardRef<unknown, InputProps>(
  (
    {
      label,
      value,
      placeholder,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      disabled = false,
      endAdornmentText,
      style,
      testId,
      autoFocus,
      guidingId,
    },
    ref
  ) => {
    const { classes } = useStyles({ disabled })

    return (
      <Box sx={style}>
        <Box sx={{ display: 'flex', marginBottom: '4px' }}>
          <Typography className={classes.label}>{label}</Typography>
          {required && (
            <Typography
              className={classes.required}
              data-testid={`${testId}-required`}
            >
              *
            </Typography>
          )}
        </Box>
        <TextField
          inputRef={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          type={'string'}
          error={error}
          helperText={helperText}
          disabled={disabled}
          className={classes.input}
          fullWidth
          variant="outlined"
          size="small"
          inputProps={{
            'data-testid': testId,
            'data-guiding-id': guidingId,
          }}
          InputProps={{
            inputComponent: IntegerOnlyMaskInput,
            endAdornment: endAdornmentText && (
              <Typography className={classes.endAdornmentText}>
                {endAdornmentText}
              </Typography>
            ),
          }}
          FormHelperTextProps={{
            id: `${testId}-helper`,
            className: `${classes.helperText} ${
              error ? classes.helperTextError : ''
            }`,
          }}
          autoFocus={autoFocus}
        ></TextField>
      </Box>
    )
  }
)

const IntegerOnlyMaskInput = React.forwardRef((props, ref) => {
  const { ...other } = props

  return (
    <MaskedInput
      {...other}
      guide={false}
      mask={createNumberMask({
        prefix: '',
        allowDecimal: false,
      })}
    />
  )
})

export default InputIntegerOnly
