import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { navigateToUrl } from 'single-spa'
import { tss } from 'tss-react/mui'

import LogoutIcon from '@mui/icons-material/Logout'
import UserProfileIcon from '@mui/icons-material/Person'
import { Menu, MenuItem, IconButton } from '@mui/material'

import { api } from '@shared/api/src'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'

import { UserIcon } from '@/assets/icons'

const useStyles = tss.withName('ProfileDropdown').create(({ theme }) => ({
  customMenu: {
    marginTop: '8px !important',
    gap: '7px',
    width: '300px',
    maxHeight: '400px',
    fontFamily: 'Inter',
  },
  avatarButton: {
    border: `solid 1px ${theme.palette['neutral-400']} !important`,
    backgroundColor: `${theme.palette['neutral-200']} !important`,
  },
  avatarIcon: {
    color: theme?.palette['power-orange'],
  },
  userLabel: {
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    alignSelf: 'stretch',
  },
  userName: {
    color: `${theme.palette['gray-tones-900']} !important`,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
  userEmail: {
    color: `${theme.palette['gray-tones-700']} !important`,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
}))

export const ProfileDropdown = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { logout } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    const redirectUrl = `${process.env.URL_BASE_PATH}/login`

    logout()
    setAnchorEl(null)
    navigateToUrl(redirectUrl)
  }

  const handleUserProfile = () => {
    const portal = sessionStorage.getItem('portal')

    const redirectUrl = `${process.env.URL_BASE_PATH}/${portal}/userprofile`

    navigateToUrl(redirectUrl)
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        className={classes.avatarButton}
        data-guiding-id="appbar-avatar"
      >
        <UserIcon
          data-testid="avatar-icon"
          fontSize="inherit"
          className={classes.avatarIcon}
        />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.customMenu }}
        data-guiding-id="appbar-avatar-menu"
      >
        <div className={classes.userLabel}>
          <span className={classes.userName}>
            {(user?.first_name || '') + ' ' + user?.last_name || '-'}
          </span>
          <span className={classes.userEmail}>{user?.email || '-'}</span>
        </div>

        <MenuItem
          onClick={handleUserProfile}
          sx={{ fontFamily: 'Inter' }}
          data-guiding-id="appbar-avatar-menu-userprofile"
        >
          <UserProfileIcon
            data-testid="user-profile-icon"
            sx={{ paddingRight: '8px' }}
          />
          {t('common.profile')}
        </MenuItem>

        <MenuItem
          onClick={handleLogout}
          sx={{ fontFamily: 'Inter' }}
          data-guiding-id="appbar-avatar-menu-logout"
        >
          <LogoutIcon data-testid="logout-icon" sx={{ paddingRight: '8px' }} />
          {t('ftp-app-bar.profile-dropdown.log-out')}
        </MenuItem>
      </Menu>
    </>
  )
}
