import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { navigateToUrl } from 'single-spa'

import { FeathersError, api } from '@shared/api'
import { User } from '@shared/api/src/schemas/types'
import mapPortalToModule, { Portal } from '@shared/mapping/portals'
import { isDeployLocal } from '@shared/utils'

interface EnforceLoginData {
  isAuthorized: boolean
  user: User | undefined
  updateUser: (user: User) => void
}

interface UpdateUserEvent extends Event {
  newUser: User
}

const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'
export const LOCAL_STORAGE_SESSION_EXP_KEY = 'session_exp'
export const LOCAL_STORAGE_SESSION_PAGE_SIZE_KEY = 'page_size'
const SESSION_STORAGE_SESSION_PORTAL_KEY = 'portal'

const EnforceLogin = createContext<EnforceLoginData | undefined>(undefined)

interface EnforceLoginContextProps {
  redirectToLogin?: boolean
  children: ReactNode
}

export const EnforceLoginProvider: React.FC<EnforceLoginContextProps> = ({
  redirectToLogin = false,
  children,
}) => {
  const [user, setUser] = useState<User | null>(null)
  const [portal, setPortal] = useState<Portal | null>(null)
  const [isAuthorized, setIsAuthorized] = useState(true)

  const checkAuthorization = async () => {
    try {
      const user = await api.service('users').authorize()
      const modules = user.module_access as number[]
      const currentModule = mapPortalToModule(portal)

      setUser(user)

      localStorage.setItem(
        LOCAL_STORAGE_SESSION_PAGE_SIZE_KEY,
        String(user?.ui_prefs?.page_size)
      )

      // TODO: Temporal workaround for the /service MFE to be Authorized
      if (portal === Portal.SERVICE) {
        sessionStorage.setItem(SESSION_STORAGE_SESSION_PORTAL_KEY, portal)
        setIsAuthorized(true)
        return
      }

      // Check if the user has access to the Current Module
      if (modules.indexOf(currentModule) !== -1) {
        sessionStorage.setItem(SESSION_STORAGE_SESSION_PORTAL_KEY, portal)
        setIsAuthorized(true)
      } else {
        setIsAuthorized(false)
      }
    } catch (error) {
      setIsAuthorized(false)
    }
  }

  const updateUser = (newUser: User) => {
    const event = new Event('ENFORCE_LOGIN_UPDATE_USER') as UpdateUserEvent
    event.newUser = newUser
    window.dispatchEvent(event)
  }

  // When the URL changes set the Portal the User is accessing
  useEffect(() => {
    setPortal(window.location.pathname.split('/')[1] as Portal)
  }, [window.location.href])

  // Redirect to login when the User isn't Authorized
  useEffect(() => {
    if (!isAuthorized && redirectToLogin) {
      const redirectUrl = `${process.env.URL_BASE_PATH}/login`

      navigateToUrl(redirectUrl)
    }
  }, [isAuthorized])

  // When the Portal or SESSION_TOKEN change, check if the User is still Authorized
  useEffect(() => {
    if (!portal || !localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN_KEY)) {
      return
    }

    checkAuthorization()
  }, [portal, localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN_KEY)])

  // Redirect the User to /login if the API produces and HTTP 401
  // (i.e. the User lacks valid credentials / session expired)
  useEffect(() => {
    const onAPIError = (error: FeathersError) => {
      if (!error.data?.error || typeof error.data?.error === 'undefined') {
        return
      }

      if (error.data.error.statusCode === 401) {
        navigateToUrl('/login')
        localStorage.removeItem(LOCAL_STORAGE_SESSION_TOKEN_KEY)
      }
    }

    const onUpdateUser = ({ newUser }: UpdateUserEvent) => {
      setUser(newUser)
    }

    api.on('apiError', onAPIError)
    window.addEventListener(
      'ENFORCE_LOGIN_UPDATE_USER',
      onUpdateUser as EventListener
    )

    return () => {
      api.off('apiError', onAPIError)
      window.removeEventListener(
        'ENFORCE_LOGIN_UPDATE_USER',
        onUpdateUser as EventListener
      )
    }
  }, [])

  return (
    <EnforceLogin.Provider
      value={{
        isAuthorized,
        user,
        // TODO: making to update the user to be running 'checkAuthorization' again doesn't seem fine,
        // maybe just expose the 'setUser' instead?
        updateUser: updateUser,
      }}
    >
      {isAuthorized ? children : null}
    </EnforceLogin.Provider>
  )
}

export const useEnforceLogin = (): EnforceLoginData => {
  const context = useContext(EnforceLogin)

  if (!context) {
    throw new Error(
      `${useEnforceLogin.name} must be used within an ${EnforceLoginProvider.name}`
    )
  }

  return context
}
