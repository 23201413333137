import { tss } from 'tss-react/mui'

import { ChevronRight } from '@mui/icons-material'
import { Breadcrumbs } from '@mui/material'

import AppTheme from '@shared/design'

const useStyles = tss.withName('CustomBreadcrumbs').create(({ theme }) => ({
  breadcrumbH2: {
    fontSize: '0.85rem',
    color: theme.palette['gray-500'] || '#6B7280',
    display: 'flex',
    gap: '0.5rem',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconSeparator: {
    color: 'inherit',
    fontSize: '1rem',
    marginTop: '0px',
  },
  iconSeparatorMarginLeft: {
    marginLeft: '8px',
  },
}))

const CustomBreadcrumbs = ({ breadcrumbs }) => {
  const { classes } = useStyles(AppTheme)
  const hasBreadcrumbs = breadcrumbs && breadcrumbs.length > 0
  const customSeparator = (
    <ChevronRight
      className={`${classes.iconSeparator} ${
        typeof breadcrumbs === 'string' ? classes.iconSeparatorMarginLeft : ''
      }`}
    />
  )

  return (
    <>
      {hasBreadcrumbs ? (
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumbH2}
          data-testid="breadcrumbs"
          data-guiding-id="appbar-breadcrumbs"
          separator={customSeparator}
        >
          {typeof breadcrumbs === 'string' ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {breadcrumbs}

              {customSeparator}
            </span>
          ) : (
            breadcrumbs.map((text, index) => (
              <span
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {text}
              </span>
            ))
          )}
        </Breadcrumbs>
      ) : null}
    </>
  )
}

export default CustomBreadcrumbs
