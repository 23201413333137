import { ApiService, App } from '../../commons'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'

export class ChargebackReportsService extends ApiService<any> {}

declare module '../../commons' {
  interface ServiceTypes {
    'chargebacks-reports': ChargebackReportsService
  }
}

export const merchantPortalChargebackReports = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'chargebacks-reports',
    new ChargebackReportsService(
      connection.service('reporting/reports/chargebacks'),
      app
    )
  )
  app.service('chargebacks-reports').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
    },
  })
}
