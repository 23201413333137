import { TransactionType } from '../../../types'
import { ApiService, App } from '../commons'
import { mapResult } from '../hooks/mapResult'
// TODO: Add type when endpoint is ready
export class SettledTransactionService extends ApiService<any> {
  async get(transactionType: TransactionType) {
    return this.apiRequest({
      url: `${transactionType}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

declare module '../commons' {
  interface ServiceTypes {
    'settled-transactions': SettledTransactionService
  }
}

export const dashboardSettledTransactions = (app: App) => {
  const connection = app.get('connection')

  app.use(
    'settled-transactions',
    new SettledTransactionService(
      connection.service('partner/dashboard/settled-transactions'),
      app
    )
  )

  app.service('settled-transactions').hooks({
    around: {
      all: [mapResult()],
    },
  })
}
