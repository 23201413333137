import { within, screen, fireEvent, act } from '@testing-library/react'

export type ClickSelectorOptionData = {
  selectorTestId: string
  optionDisplayText: string | RegExp
}

/**
 *
 * **Testing Utility**
 *
 * Click a specific option from a selector.
 * @param {ClickSelectorOptionData} data The data needed to click the selector option.
 */
export const clickSelectorOption = async (data: ClickSelectorOptionData) => {
  const { selectorTestId, optionDisplayText } = data

  const selectButton = within(screen.getByTestId(selectorTestId)).getByRole(
    'button',
    { hidden: true }
  )
  await act(async () => {
    fireEvent.mouseDown(selectButton)
  })

  const listbox = await screen.findByRole('listbox')
  const option = within(listbox).getByText(optionDisplayText)

  await act(async () => {
    fireEvent.mouseDown(selectButton)
  })

  await act(async () => {
    fireEvent.click(option)
  })
}
